import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { DiscountGroup, EAgreementUpdateRequest, MyHttpApi, PrivilegeItem, Product, SalesChannel, SalesRestriction } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

@autoinject
export class EAgreementsEdit {
	private eAgreement: EAgreementUpdateRequest = {
		name: '',
		validityStart: new Date(),
		validityEnd: new Date(),
		delete: false,
		type: 'CLIENT',
		privilegeId: 0,
		productIdList: [],
	};

	private discountGroupList: DiscountGroup[] = [];
	private extraDiscountGroup?: DiscountGroup;
	private extraSalesChannel?: SalesChannel;
	private extraSalesRestriction?: SalesRestriction;
	private privilege?: PrivilegeItem;
	private productList: Product[] = [];
	private salesChannelList: SalesChannel[] = [];
	private salesRestrictionList: SalesRestriction[] = [];
	private selectedProductList: Product[] = [];
	private typeAndName = "";

	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
	}

	async activate(params: { id?: string; }) {
		this.privilege = getPrivilege();
		if (!this.privilege) {
			return;
		}

		const [ discountGroupList, productList, salesChannelList, salesRestrictionList ] = await Promise.all([
			this.api.discountGroupList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' })
		]);

		this.discountGroupList = discountGroupList.filter(x => !x.deleteTime);
		this.productList = productList.filter(x => !x.deleteTime);
		this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);

		if (params.id) {
			const id = parseInt(params.id);
			const [eAgreement, selectedProductIdList] = await Promise.all([
				this.api.eAgreementById({ id }),
				this.api.eAgreementListProductIdsByEAgreementId({ eAgreementId: id })
			]);

			this.eAgreement = {
				...eAgreement,
				delete: !!eAgreement.deleteTime,
				type: this.privilege.type,
				privilegeId: this.privilege.id,
				productIdList: [],
			};

			const productMap = MyHttpApi.toHashStr(productList);
			this.selectedProductList = selectedProductIdList.map(productId => productMap[productId]);

			this.extraDiscountGroup = discountGroupList.find(x => x.id === eAgreement.discountGroupId);
			this.extraSalesChannel = salesChannelList.find(x => x.id === eAgreement.salesChannelId);
			this.extraSalesRestriction = salesRestrictionList.find(x => x.id === eAgreement.salesRestrictionId);

			this.privilege = privilegeItemFromElement(eAgreement);
		}

		if (!this.privilege) {
			return;
		}

		this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
	}

	async save(deleted: boolean) {
		if (!this.privilege) {
			return;
		}
		await this.api.eAgreementUpdate({
			...this.eAgreement,
			productIdList: this.selectedProductList.map(x => x.id),
			privilegeId: this.privilege.id,
			type: this.privilege.type,
			delete: deleted,
		});
		this.router.navigateBack();
	}
}
